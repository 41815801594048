 @tailwind base;
 @tailwind components;
 @tailwind utilities;

 
 @font-face {
  font-family: "B_lotus";
  src: local("B_lotus"),
   url("./fonts/B_lotus.ttf") format("truetype");
  /* font-weight: bold; */
 }


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121 !important;

  font-size: medium;
}

