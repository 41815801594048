
.histoy {
  background-color: #dedddf;
  height: 50vh;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}


.chatscreen {
  height: 60vh;
}

.chat-list {
  height: 60vh;
  overflow-x: hidden;
  background-color: transparent;
}

.chat-list p {
  direction: rtl;
  font-size: 20px;
  font-weight: 300;
}
/* .chatroomcompo:hover {
  border: none;
} */

.leftlogo img {
  width: 48px;
  height: 32px;
}

.leftlogo p {
  font-weight: 500;
  font-size: 24px;
}


/* for scroollllll */
#style-2::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: transparent;

}

#style-2::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .7);
  background-color: #5F687C;
}

/*  */
.scrollbar2 {
  background: inherit;
  overflow-y: scroll;
}

#style-3::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 50px;
  background-color: transparent;
}

#style-3::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  ;
}

#style-3::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .7);
  background-color: #5F687C;
}

.chat-input {
  background-color: transparent;
}

.chat-input:focus {
  outline: none;
}

#sendChat {
  direction: rtl;
  background: #4C5569;

}

#recievedChat {
  direction: rtl;
  background: #1b2a3d;
}

.logoInChat {
  width: 3em;
}

.profileIcon {
  width: 2em;

}

.insideP {
  direction: rtl;
}

.insideP p {
  font-size: 40px;
  font-weight: 600;
}

.insideP .helloP {
  background: linear-gradient(#28272b, #4723D9, #515053);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btnChat {
  position: relative;
  left: auto;
}

#sendIcon {
  rotate: 180deg;
}

.iconify-icon {
  color: white;
}

/* checked */
.btn-service {
  border: none;
  display: flex;
  background: #3B5893;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.sparkle {
  fill: black;
  transition: all 100ms ease;
}

.text {
  font-weight: 600;
  color: black;
  font-size: small;
}

.btn-service:hover {
  background: linear-gradient(0deg, #0c1e3a, #19335c);
  box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
    inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 4px rgba(255, 255, 255, 0.2),
    0px 0px 50px 0px #43354e;
  transform: translateY(-2px);
  border: none;
}

.btn-service:hover .service-text {
  color: white;
}

.btn-service:hover .sparkle {
  fill: white;
  transform: scale(0.5);
}
/* checked */

.button-explore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  color: rgb(19, 19, 19);
  font-weight: 600;
  border: none;
  position: relative;
  cursor: pointer;
  transition-duration: .2s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.116);
  padding-left: 8px;
  transition-duration: .5s;
}


.svgIcon {
  height: 25px;
  transition-duration: 1.5s;
}

.bell path {
  fill: rgb(19, 19, 19);
}


.button-explore:hover {
  background-color: #3B5893;
  transition-duration: .5s;
}

.button-explore:active {
  transform: scale(0.97);
  transition-duration: .2s;
}

.button-explore:hover .svgIcon {
  transform: rotate(250deg);
  transition-duration: 1.5s;
}

