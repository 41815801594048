@-webkit-keyframes move-up-alternate {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(80%);
    }

    51% {
        transform: translateY(-80%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes move-up-alternate {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(80%);
    }

    51% {
        transform: translateY(-80%);
    }

    to {
        transform: translateY(0);
    }
}


.container {
    box-shadow: 0 14px 28px rgba(97, 97, 99, 0.25),
        0 10px 10px rgba(88, 84, 84, 0.22);
    z-index: 1;
}



.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.right-panel-active .sign-in-container {
    transform: translateX(-100%);
    opacity: 0;
    z-index: 1;
}

.forget-pass {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
    background: inherit;
    transition: transform 0.6s ease-in-out;
}

.container.right-panel-active4 .forget-pass {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
    transition: transform 0.6s ease-in-out;

}

.verify-opt {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
    background: inherit;
    transition: transform 0.6s ease-in-out;
}


.container.right-panel-active5 .verify-opt {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

.new-pass {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;
    /* background-image: url(../assets/back.jpg); */
    background: inherit;

}

.container.right-panel-active6 .new-pass {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

.container.right-panel-active6 .sign-in-container {
    transform: translateX(-100%);
    opacity: 0;
    z-index: 1;
}

.sign-up-container {
    right: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;
}

.bg.right-panel-active .sign-up-container {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

.verify-container {
    right: 0;
    width: 50%;
    opacity: 1;
    z-index: 1;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;
    background: rgba(25, 26, 26, 0.4);
    background-image: url(../assets/6.jpg);



}

.container.right-panel-active2 .verify-container {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

.company-container {
    right: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;
    background: rgba(25, 26, 26, 0.4);
    background-image: url(../assets/6.jpg);

}

.container.right-panel-active3 .company-container {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

@keyframes show {

    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}
.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}



.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    background: rgba(20, 39, 116, 0);
    backdrop-filter: blur(10px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}



.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}


.confirm-alert-button {
    padding: 10px 50px;
    margin: 5px 70px;
    cursor: pointer;
    border: 1px solid #443875;
    border-radius: 5px;
    color: #333;
}

.confirm-alert-button:hover {
    background-color: #443875;
}



