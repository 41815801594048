
/* Width of the vertical scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#mySidenav {
    width: 0;
    transition: 0.5s; /* Add transition for smooth animation */

}

.sidenav {
    background-color: #1d1f31;
    transition: 0.5s;
}




.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: inherit;
}





.scrollbar2 {
    background: inherit;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
}


.settinglg-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(40, 40, 41, 0.9);
    z-index: 9999;

}

.settinglg-container .main {
    background-color: #1d1d1f;
}

.settinglg-container .main a:hover {
    background-color: #7289F4;
    cursor: pointer;
}






.sendinput {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: inherit;
    border-radius: 1px solid #2F3334;
    margin-bottom: 0.4em;
}

.dropdown button:hover,
.dropdown ul :hover {
    background-color: #58585f;
}

#select_Servic {
    background-color: inherit;
    color: white;
}

#select_Servic option {
    color: white;
    background: #221f1f;
}

.custom-tooltip {
    font-size: 30px;
    /* Adjust font size as needed */
}

#title {
    height: 5vh;
    width: 1vh;
}

.title-text {
    font-size: x-small;
}


.active {
    cursor: pointer;
}

.inactive {
    cursor: not-allowed;
    opacity: 0.5;
}

.code-snippet {
    font-size: large;
}

