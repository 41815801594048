
.hljs {
  display:block;
  overflow-x: hidden;
  padding: 0.5em;
  overflow-y: auto;
  background-color:#2B2B2B;
  font-size: medium;
}

.hljs-built_in,
.hljs-selector-tag,
.hljs-section,
.hljs-link {
  color: #8be9fd;
  font-size: medium;

}

.hljs-keyword {
  color: #2041d1;
  font-size: medium;

}

.hljs,
.hljs-subst {
  color: #f8f2f2;
  font-size: medium;

}

.hljs-title,
.hljs-attr,
.hljs-meta-keyword {
  font-style: italic;
  color: #50fa7b;
  font-size: medium;

}

.hljs-string,
.hljs-meta,
.hljs-name,
.hljs-type,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
  color: #f1fa8c;
  font-size: medium;

}

.hljs-comment,
.hljs-quote,
.hljs-deletion {
  color: #6272a4;
  font-size: medium;

}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-title,
.hljs-section,
.hljs-doctag,
.hljs-type,
.hljs-name,
.hljs-strong {
  font-weight: bold;
  font-size: medium;

}

.hljs-literal,
.hljs-number {
  color: #bd93f9;
  font-size: medium;

}

.hljs-emphasis {
  font-style: italic;
  font-size: medium;

}
