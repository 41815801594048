.sweet-alert-container {
    z-index: 9999; /* set the z-index value as needed to ensure it appears above all other elements */
  }

  .swal2-popup {
    background-color: #212121; 
    /* /* border-top-right-radius: 25%; */
  }
  
  /* Target the background of the title */
  .swal2-title {
    border-radius: 10%;
    background-color: #212121; /* Set the desired background color */
    color: #ffffff; /* Set the text color for the title */
  }
  
  /* Target the background of the content area */
  .swal2-content {
    background-color: #ffffff; /* Set the desired background color */
  }
  
  /* Target the background of the confirm and cancel buttons */
  .swal2-confirm, .swal2-cancel {
    background-color: #7289F4; /* Set the desired background color */
    color: #ffffff; /* Set the text color for the buttons */
  }


  
*,
::before,
::after {
    box-sizing: border-box
}

body {
    position: relative;
    /* font-family: var(--body-font); */
    font-size:
        var(--normal-font-size);
    transition: .5s;
    direction: ltr;
}

/* Sidebar style */
a {
    text-decoration: none
}

.header {
    width: 100%;
    height:
        var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    background-color: #d7d9de;
    z-index:
        var(--z-fixed);
    transition: .5s
}

.header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.header_img {
    display: flex;
    justify-content: center;
    border-radius: 25%;
    overflow: hidden;
}


.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width:
        var(--nav-width);
    height: auto;
    background-color: #5F687C;
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed)
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}

.nav_logo{
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem
}

.nav_logo {
    margin-bottom: 2rem
}

.nav_logo-icon {
    font-size: 5rem;
    color: var(--white-color)
}

.nav_logo-name {
    color: var(--white-color);
    font-weight: 700
}

.nav_link:hover {
    color: var(--white-color)
}

.nav_icon {
    font-size: 2rem
}




.active {
    color: var(--white-color)
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color)
}

.height-100 {
    height: 100vh
}








/* Styles for phones */
@media screen and (max-width: 767px) {
    body {
        margin: var(--header-height) 0 0 0;
        padding-left: var(--nav-width);
        width: fit-content;
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 1rem);
    }


    .header_toggle {
        position: relative;
        left: -60px;
        z-index: 1100
    }

    #closeIcon {
        position: absolute;
        right: 100px;
        z-index: 1;
    }

    .l-navbar {
        visibility: hidden;
    }

    .l-navbar.show {
        left: 0;
        visibility: visible;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: 100;
    }

    .l-navbar.show #closeIcon {
        display: inline;
        visibility: visible;
        color: whitesmoke;
        transform: translateX(50px);
        top: 10px;
        z-index: 20000;
    }

    .nav {
        padding: 1rem 1rem 0 0;
        width: 30rem;
    }

    .show {
        width: calc(var(--nav-width) + 100px);
        /* Adjust this to fit the layout */
        margin: calc(var(--nav-width)+ 100px);
        /* Add margin to the content when the navbar is shown */
    }

    .body-pd {
        padding-left: calc(var(--nav-width));
    }

}

/* For Small Desktops/Laptops (768px to 1023px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    body {
        margin: var(--header-height) 0 0 0;
        padding-left: var(--nav-width);
    }

    #header {
        height: calc(var(--header-height));
        padding: 0 2rem 0 calc(var(--nav-width) + 1rem);
    }

    .header_img {
        width: 5rem;
        height: 40px;
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0
    }

    .l-navbar.show {
        left: 0;
    }

    .show {
        width: calc(var(--nav-width) + 100px);
        /* Adjust this to fit the layout */
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 70px);
        /* Adjust this to fit the layout */
        top: 0px;
        height: 100vh;
    }

}

/* For Small Desktops/Laptops (1024px to 1279px): */
@media screen and (min-width: 1024px) and (max-width: 1279px) {
    body {
        margin: calc(var(--header-height)) 0 0 0;
        padding-left: calc(var(--nav-width))
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    .header_img {
        width: 52px;
        height: 40px
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0
    }

    .l-navbar.show {
        left: 0;
        /* Change this to match the tablet media query */
    }

    .show {
        width: calc(var(--nav-width) + 100px);
        /* Adjust this to fit the layout */
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 80px);
        /* Adjust this to fit the layout */
    }


}

/* For Medium Desktops/Laptops (1280px to 1439px): */
@media screen and (min-width: 1280px) and (max-width: 1439px) {

    body {
        margin: calc(var(--header-height)) 0 0 0;
        padding-left: calc(var(--nav-width))
    }

    .header {
        height: calc(var(--header-height));
        padding: 0 2rem 0 calc(var(--nav-width) + 1rem)
    }

    .header_img {
        width: 52px;
        height: 40px
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0
    }

    .l-navbar.show {
        left: 0;
    }

    .show {
        width: calc(var(--nav-width) + 100px)
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 80px)
    }


}

/* For Large Desktops/Laptops (1440px and above): */
@media screen and (min-width: 1440px) {
    body {
        margin: calc(var(--header-height)) 0 0 0;
        padding-left: calc(var(--nav-width))
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    .header_img {
        width: 52px;
        height: 40px
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0
    }

    .l-navbar.show {
        left: 0;
    }

    .show {
        width: calc(var(--nav-width) + 100px)
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 80px)
    }

}