.buttons {
    font-size: 17px;
    padding: 1em 2.7em;
    font-weight: 500;
    background: #1f2937;
    color: white;
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: 0.6em;
    cursor: pointer;
}

.gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0.6em;
    margin-top: -0.25em;
    background-image: linear-gradient(rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.3));
}

.label {
    position: relative;
    top: -1px;
}

.transition {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: rgba(21, 18, 36, 0.6);
    border-radius: 9999px;
    width: fit-content;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.buttons:hover .transition {
    width: 14em;
    height: 14em;
}

.buttons:active {
    transform: scale(0.97);
}



.mainrow {
    height: 70vh;
}

.mainrow button {
    background-color: #576CA8;

}

#logo {
    position: absolute;
    top: 0;
}

.logo {
    display: inline-block;
    vertical-align: middle;
    width: 20vw !important;
}

.text-white {
    display: inline-block;
    vertical-align: middle;
    color: white;
}

h6 {
    position: relative;
    top: -8px;
}




/* login */
#loginphone,
#loginpass {
    color: black;
    font-size: small;
    padding: 12px;
    direction: rtl;
    border: 1px solid rgb(175, 172, 172);
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 300px;
    padding-top: 100px;
    width: 100%;

}



.container {
    position: relative;
    height: 55vh;
    width: 100%;
    overflow: hidden;
    margin: auto;
    z-index: 1;
    padding: auto;
}

.form-container {
    position: relative;
    transition: all 0.6s ease-in-out;
    width: fit-content;
}

.signup {
    width: 100%;
}
.verify {
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 1;
    top: 0;
    background-color: #d0d0d4;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.container.right-panel-active2 .verify {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
    left: 0;
}

.verify span {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: xx-small;
}

.company {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #d0d0d4;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.container.right-panel-active3 .company {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

.company select{
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid gray;
    background-color: inherit;
}


.forgetPass{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    background-color: #d0d0d4;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out,
     opacity 0.6s ease-in-out;
}

.container.right-panel-active4 .forgetPass {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;

}

.verifyOpt {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    background-color: #d0d0d4;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out,
     opacity 0.6s ease-in-out;
}


.container.right-panel-active5 .verifyOpt {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

.newPass {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    background-color: #d0d0d4;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out,
     opacity 0.6s ease-in-out;
}

.container.right-panel-active6 .newPass {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}

.container.right-panel-active7 .signin {
    transform: translateX(-100%);
    opacity: 0;
    z-index: 1;
}


input {
    background: #c9c4c4;
    direction: rtl;
    border: none;
    padding: 6px 20px;

}

input:focus {
    outline-color: 443875;
}

input[type=email] {
    direction: rtl;
}

button {
    border-radius: 20px;
    color: #fcfcff;
    /* font-size: 25px; */
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

button:active {
    transform: scale(0.95);
}

button:focus {
    outline: none;
}

.signupcontainer .btn {
    position: relative;
    z-index: 1;
}

button.btn-continue {
    box-shadow: 20px 10px 5px #adadad;

}

button.btn-login,
.btn-verify,
button.btn-continue,
.btn-pass,
button.btn-verify,
button.btn-company {
    background-color: #11181B;
    color: #2070D8;
    font-size: 15px;
    border: none;
}


.btn-login,
.btn-login *,
.btn-login:after,
.btn-login :before,
.btn-login:after,
.btn-login:before,

.btn-signup,
.btn-signup *,
.btn-signup:after,
.btn-signup :before,
.btn-signup:after,
.btn-signup:before,

.btn-verify,
.btn-verify *,
.btn-verify:after,
.btn-verify :before,
.btn-verify:after,
.btn-verify:before .btn-continue,
.btn-continue *,
.btn-continue:after,
.btn-continue :before,
.btn-continue:after,
.btn-continue:before .btn-company,
.btn-company *,
.btn-company:after,
.btn-company :before,
.btn-company:after,
.btn-company:before {
    border: 0 solid;
    box-sizing: border-box;
}

.btn-login,
.btn-signup,
.btn-verify,
.btn-continue,
.btn-company {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: button;
    background-color: #fff;
    background-image: none;
    color: #fff;
    cursor: pointer;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 100%;
    font-weight: 900;
    line-height: 1.5;
    margin: 0;
    -webkit-mask-image: -webkit-radial-gradient(#adadad, #fff);
    padding: 0;
}


.btn-login:disabled,
.btn-signup:disabled,
.btn-verify:disabled,
.btn-continue :disabled,
.btn-company :disabled {
    cursor: default;
}

.btn-login:-moz-focusring,
.btn-signup:-moz-focusring,
.btn-verify:-moz-focusring,
.btn-continue :-moz-focusring,
.btn-company :-moz-focusring {
    outline: auto;
}

.btn-login svg {
    display: block;
    vertical-align: middle;
}

.btn-login[hidden],
.btn-signup[hidden],
.btn-verify[hidden],
.btn-continue[hidden],
.btn-company[hidden] {
    display: none;
}

.btn-login,
.btn-signup,
.btn-verify,
.btn-continue,
.btn-company {
    border-width: 1px;
    padding: 1rem 3rem;
    position: relative;
    text-transform: uppercase;
}

.btn-login:before,
.btn-signup:before,
.btn-verify:before,
.btn-continue:before,
.btn-company:before {
    --progress: 100%;
    background: #fff;
    -webkit-clip-path: polygon(100% 0,
            var(--progress) var(--progress),
            0 100%,
            100% 100%);
    clip-path: polygon(100% 0,
            var(--progress) var(--progress),
            0 100%,
            100% 100%);
    content: "";
    inset: 0;
    position: absolute;
    transition: -webkit-clip-path 0.2s ease;
    transition: clip-path 0.2s ease;
    transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
}

.btn-login:hover:before,
.btn-signup:hover::before,
.btn-verify:hover::before,
.btn-continue:hover::before,
.btn-company:hover::before {
    --progress: 0%;
}

.btn-login .btn-container,
.btn-signup .btn-container,
.btn-verify .btn-container,
.btn-continue .btn-container,
.btn-company .btn-container {
    display: block;
    overflow: hidden;
    position: relative;
}

.btn-login .btn-text,
.btn-signup .btn-text,
.btn-verify .btn-text,
.btn-continue .btn-text,
.btn-company .btn-text {
    display: block;
    font-weight: 900;
    mix-blend-mode: difference;
    position: relative;
    background: transparent;
}

.btn-login:hover .btn-text,
.btn-signup:hover .btn-text,
.btn-verify:hover .btn-text,
.btn-continue .btn-text,
.btn-company .btn-text {
    -webkit-animation: move-up-alternate 0.3s ease forwards;
    animation: move-up-alternate 0.3s ease forwards;
}

.btn-container {
    background-color: rgb(4, 9, 29);
}


@media screen and (min-width:400) and (max-width:767px){


    
}

@media screen and (max-width: 768px) {
    .largscreenform {
        display: none;
    }


}


@media (min-width: 768px) {

    .formobile,
    .signupcontainer {
        display: none;
    }


    .bg {
        background-image: url('../assets/6.jpg');
        height: 100vh;
        width: fit-content;
        background-repeat: no-repeat;
        background-size: cover;
    }

    h1,
    h6 {
        font-weight: bold;
        margin: 0;
        background: transparent;
        color: #000;
    }

    h2 {
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #ffffff;
        margin: 20px 0 30px;
        background: transparent;

    }

    span {
        font-size: 12px;
    }

    a {
        color: #333;
        font-size: 14px;
        text-decoration: none;
        margin: 15px 0;
    }

    button {
        border-radius: 20px;
        color: #fcfcff;
        /* font-size: 25px; */
        font-weight: bold;
        padding: 12px 45px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: transform 80ms ease-in;
    }

    button:active {
        transform: scale(0.95);
    }

    button:focus {
        outline: none;
    }


    button.btn-login,
    .btn-verify,
    button.btn-continue,
    .btn-pass,
    button.btn-verify,
    button.btn-company {
        background-color: #11181B;
        color: #2070D8;
        font-size: 80px;
        border: none;
    }


    .btn-login,
    .btn-login *,
    .btn-login:after,
    .btn-login :before,
    .btn-login:after,
    .btn-login:before,

    .btn-signup,
    .btn-signup *,
    .btn-signup:after,
    .btn-signup :before,
    .btn-signup:after,
    .btn-signup:before,

    .btn-verify,
    .btn-verify *,
    .btn-verify:after,
    .btn-verify :before,
    .btn-verify:after,
    .btn-verify:before .btn-continue,
    .btn-continue *,
    .btn-continue:after,
    .btn-continue :before,
    .btn-continue:after,
    .btn-continue:before .btn-company,
    .btn-company *,
    .btn-company:after,
    .btn-company :before,
    .btn-company:after,
    .btn-company:before 

    .btn-pass *,
    .btn-pass:after,
    .btn-pass :before,
    .btn-pass:after,
    .btn-pass:before 

    {
        border: 0 solid;
        box-sizing: border-box;
    }

    .btn-login,
    .btn-pass,
    .btn-signup,
    .btn-verify,
    .btn-continue,
    .btn-company {
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: button;
        background-color: #fff;
        background-image: none;
        color: #fff;
        cursor: pointer;
        font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
            Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
            Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 100%;
        font-weight: 900;
        line-height: 1.5;
        margin: 0;
        -webkit-mask-image: -webkit-radial-gradient(#adadad, #fff);
        padding: 0;
    }


    .btn-login:disabled,
    .btn-signup:disabled,
    .btn-verify:disabled,
    .btn-continue :disabled,
    .btn-company :disabled,
    .btn-pass :disabled {
        cursor: default;
    }

    .btn-login:-moz-focusring,
    .btn-signup:-moz-focusring,
    .btn-verify:-moz-focusring,
    .btn-continue :-moz-focusring,
    .btn-company :-moz-focusring 
    .btn-pass  :-moz-focusring  
        {
        outline: auto;
    }

    .btn-login svg {
        display: block;
        vertical-align: middle;
    }

    .btn-login[hidden],
    .btn-signup[hidden],
    .btn-verify[hidden],
    .btn-continue[hidden],
    .btn-company[hidden],
    .btn-pass[hidden] {
        display: none;
    }

    .btn-login,
    .btn-signup,
    .btn-verify,
    .btn-continue,
    .btn-company,
    .btn-pass {
        border-width: 1px;
        padding: 1rem 3rem;
        position: relative;
        text-transform: uppercase;
    }

    .btn-login:before,
    .btn-signup:before,
    .btn-verify:before,
    .btn-continue:before,
    .btn-company:before,
    .btn-pass ::before {
        --progress: 100%;
        background: #fff;
        -webkit-clip-path: polygon(100% 0,
                var(--progress) var(--progress),
                0 100%,
                100% 100%);
        clip-path: polygon(100% 0,
                var(--progress) var(--progress),
                0 100%,
                100% 100%);
        content: "";
        inset: 0;
        position: absolute;
        transition: -webkit-clip-path 0.2s ease;
        transition: clip-path 0.2s ease;
        transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
    }

    .btn-login:hover:before,
    .btn-signup:hover::before,
    .btn-verify:hover::before,
    .btn-continue:hover::before,
    .btn-company:hover::before 
    .btn-pass :hover::before{
        --progress: 0%;
    }

    .btn-login .btn-container,
    .btn-signup .btn-container,
    .btn-verify .btn-container,
    .btn-continue .btn-container,
    .btn-company .btn-container ,
    .btn-pass .btn-container
    {
        display: block;
        overflow: hidden;
        position: relative;
    }

    .btn-login .btn-text,
    .btn-signup .btn-text,
    .btn-verify .btn-text,
    .btn-continue .btn-text,
    .btn-company .btn-text
    .btn-pass  .btn-text {
        display: block;
        font-weight: 900;
        mix-blend-mode: difference;
        position: relative;
        background: transparent;
    }

    .btn-login:hover .btn-text,
    .btn-signup:hover .btn-text,
    .btn-verify:hover .btn-text,
    .btn-continue .btn-text,
    .btn-company .btn-text,
    .btn-pass .btn-text  {
        -webkit-animation: move-up-alternate 0.3s ease forwards;
        animation: move-up-alternate 0.3s ease forwards;
    }

    .btn-container {
        background-color: rgb(4, 9, 29);
    }

    @-webkit-keyframes move-up-alternate {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(80%);
        }

        51% {
            transform: translateY(-80%);
        }

        to {
            transform: translateY(0);
        }
    }

    @keyframes move-up-alternate {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(80%);
        }

        51% {
            transform: translateY(-80%);
        }

        to {
            transform: translateY(0);
        }
    }

    button.ghost {
        background-color: transparent;
        border-color: #FFFCFF;

    }



    form {
        background: rgba(40, 41, 41, 0.6);
        backdrop-filter: blur("20px");
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 50px;
        height: 100%;
        text-align: center;
        direction: rtl;
    }



    input {
        background: #d4cece;
        direction: rtl;
        border: none;
        padding: 8px 15px;
        width: 100%;

    }

    input:focus {
        outline-color: 443875;
    }

    input[type=email] {
        direction: rtl;
    }

    iconify-icon {
        cursor: pointer;
        color: white;
    }


    .container {
        border-radius: 10px;
        background-image: url("../assets/6.jpg");
        box-shadow: 0 14px 28px rgba(97, 97, 99, 0.25),
            0 10px 10px rgba(88, 84, 84, 0.22);
        position: fixed;
        overflow: hidden;
        background-size: cover;
        z-index: 1;
        inset: 0px;
        margin: auto;

    }

    .form-container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }

    .sign-in-container {
        left: 0;
        width: 50%;
        z-index: 2;
    }

    .container.right-panel-active .sign-in-container {
        transform: translateX(-100%);
        opacity: 0;
        z-index: 1;
    }

    .forget-pass {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
        transform: translateX(100%);
        background: inherit;
        transition: transform 0.6s ease-in-out;
    }

    .container.right-panel-active4 .forget-pass {
        transform: translateX(0%);
        opacity: 1;
        z-index: 2;
        transition: transform 0.6s ease-in-out;

    }

    .verify-opt {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
        transform: translateX(100%);
        background: inherit;
        transition: transform 0.6s ease-in-out;
    }


    .container.right-panel-active5 .verify-opt {
        transform: translateX(0%);
        opacity: 1;
        z-index: 2;
    }

    .new-pass {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
        transform: translateX(100%);
        transition: transform 0.6s ease-in-out;
        background: inherit;

    }

    .container.right-panel-active6 .new-pass {
        transform: translateX(0%);
        opacity: 1;
        z-index: 2;
    }

    .container.right-panel-active6 .sign-in-container {
        transform: translateX(-100%);
        opacity: 0;
        z-index: 1;
    }

    .signup {
        right: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
        transform: translateX(100%);
        transition: transform 0.6s ease-in-out;
    }

    .container.right-panel-active .signup {
        transform: translateX(0%);
        opacity: 1;
        z-index: 2;
    }

    .verify-container {
        right: 0;
        width: 50%;
        opacity: 1;
        z-index: 1;
        transform: translateX(100%);
        transition: transform 0.6s ease-in-out;
        background: rgba(25, 26, 26, 0.4);
        background-image: url(../assets/6.jpg);



    }

    .container.right-panel-active2 .verify-container {
        transform: translateX(0%);
        opacity: 1;
        z-index: 2;
    }

    .company-container {
        right: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
        transform: translateX(100%);
        transition: transform 0.6s ease-in-out;
        background: rgba(25, 26, 26, 0.4);
        background-image: url(../assets/6.jpg);

    }

    .container.right-panel-active3 .company-container {
        transform: translateX(0%);
        opacity: 1;
        z-index: 2;
    }

    @keyframes show {

        0%,
        49.99% {
            opacity: 0;
            z-index: 1;
        }

        50%,
        100% {
            opacity: 1;
            z-index: 5;
        }
    }

    .overlay-container {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
    }

    .container.right-panel-active .overlay-container {
        transform: translateX(-100%);
    }

    .overlay {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        color: #FFFFFF;
        position: relative;
        left: -100%;
        height: 100%;
        width: 200%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }



    .container.right-panel-active .overlay {
        transform: translateX(50%);
    }

    .overlay-panel {
        background: rgba(20, 39, 116, 0);
        backdrop-filter: blur(10px);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        text-align: center;
        top: 0;
        height: 100%;
        width: 50%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }

    .overlay-left {
        transform: translateX(-20%);
    }

    .container.right-panel-active .overlay-left {
        transform: translateX(0);
    }



    .overlay-right {
        right: 0;
        transform: translateX(0);
    }

    .container.right-panel-active .overlay-right {
        transform: translateX(20%);
    }

    .social-container {
        margin: 20px 0;
    }

    .social-container a {
        border: 1px solid #DDDDDD;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        height: 40px;
        width: 40px;
    }


    .confirm-alert-button {
        padding: 10px 50px;
        margin: 5px 70px;
        cursor: pointer;
        border: 1px solid #443875;
        border-radius: 5px;
        color: #333;
    }

    .confirm-alert-button:hover {
        background-color: #443875;
    }

}